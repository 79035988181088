import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: calc(100% - 80px);
  padding-right: 40px;
  padding-left: 40px;
  padding-top: 30px;
  
  .inner {
    max-width: 1210px;
    width: 100%;
    margin: 0 auto;
    .copy {
      background: #007DBA;
      position:relative;
      padding: 64px 67px;
      border-radius: 19px;

      h2 {
        font-family: Oswald;
        font-style: normal;
        font-weight: 300;
        font-size: 32px;
        line-height: 36px;

        margin-top: 0;
        color: white;
      }
      .text {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 36px;
        letter-spacing: 0.02em;
        color: white;
        column-count: 2;
        column-gap: 46px;
        @media (max-width: 850px) {
          column-count: unset;
        }
      }
    }
  }
  .wrap-arrow {
    max-width: 1004px;
    position:relative;
    margin: 0 auto;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: stretch;
    align-content: stretch;

    .part {
      width: 230px;
    }
    

    .arrow-up {
      position:absolute;
      top: -65px;
      right: 10px;
      position: absolute;
      /* bottom: 100%; */
      /* right: 100px; */
      width: 0px;
      height: 0px;
      border-left: 116px solid transparent;
      border-right: 116px solid transparent;
      border-bottom: 65px solid #007DBA;
      z-index: 2;
    }
  }

  @media (max-width: 1115px) {
    .wrap-arrow .arrow-up{
      top: -50px; 
    }
  }
  @media (max-width: 1085px) {
    .part {
      position:relative;
    }
    .wrap-arrow { 
      max-width: 800px;
      .arrow-up {
        top: -65px; 
      }
    }
  }
  @media (max-width: 775px) {
    .wrap-arrow { 
      max-width: 600px;
    }
  }
  @media (max-width: 750px) {
    width: calc(100% - 40px);
    padding-right: 20px;
    padding-left: 20px;
    .inner .copy {
      padding: 30px;
    }
  }
  @media (max-width: 610px) {
    .wrap-arrow .arrow-up{
      top: -50px; 
    }
  }
  @media (max-width: 545px) {
    .wrap-arrow { 
      max-width: 300px;
      .arrow-up {
        top: -65px; 
      }
    }
  }
`;

function DetailsWindow(props) {
  return (
    <Wrapper>
      <div className="wrap-arrow">
        <div className="part"></div>
        <div className="part">
          <div className="arrow-up"></div>
        </div>
      </div>
      <div className="inner">
        <div className="copy">
          <h2>{props.title}</h2>
          <div className="text"  dangerouslySetInnerHTML={{ __html: props.html }}></div>
        </div>
      </div>
    </Wrapper>
  );
}

export default DetailsWindow;