import { useEffect, useRef, useCallback } from 'react';
// import { useImmerReducer } from 'use-immer';
// import produce from 'immer';

class AnimationFrameLoop {
  constructor(fps = 60, animate) {
    this.requestID = 0;
    this.fps = fps;
    this.animate = animate;
  }

  start() {
    let then = performance.now();
    const interval = 1000 / this.fps;
    const tolerance = 0.1;

    const animateLoop = (now) => {
      this.requestID = requestAnimationFrame(animateLoop);
      const delta = now - then;

      if (delta >= interval - tolerance) {
        then = now - (delta % interval);
        this.animate(delta);
      }
    };
    this.requestID = requestAnimationFrame(animateLoop);
  }

  stop() {
    cancelAnimationFrame(this.requestID);
  }
}

const reducer = (draft, action) => {
  switch (action.type) {
    case 'removeItem':
      draft = draft.filter((item) => {
        return action.id != item.id;
      });

      return draft;
    case 'registerItem':
      draft.push({
        id: action.id,
        start: action.start,
        end: action.end,
        onUpdate: action.onUpdate,
      });
      return draft;
    case 'updateScroll':
      draft.forEach((item) => {
        item.didUpdate = false;

        let type = '';
        if (window.scrollY > item.start && window.scrollY < item.end) {
          //ACTIVE
          type = 'active';

          item.isActive = true;
          item.isComplete = false;
          item.isInitial = false;
          item.scrollPos = window.scrollY;
          item.scrollOffset = window.scrollY - item.start;
          item.scrollPct = (window.scrollY - item.start) / (item.end - item.start);
          item.didUpdate = true;
        } else if (window.scrollY <= item.start && !item.isInitial) {
          type = 'initial';
          //INTIAL
          item.isInitial = true;
          item.isActive = false;
          item.isComplete = false;
          item.scrollPos = window.scrollY;
          item.scrollOffset = 0;
          item.scrollPct = 0;
          item.didUpdate = true;
        } else if (window.scrollY >= item.end && !item.isComplete) {
          type = 'complete';
          //COMPLETE
          item.isComplete = true;
          item.isActive = false;
          item.isInitial = false;
          item.scrollPos = window.scrollY;
          item.scrollOffset = item.end - item.start;
          item.scrollPct = 1;
          item.didUpdate = true;
        }
      });
      return draft;
    case 'updateItem':
      let item = draft.find((item) => item.id === action.id);
      item.start = action.start;
      item.end = action.end;
      if (action.onUpdate) {
        item.onUpdate = action.onUpdate;
      }
      return draft;
    default:
      return draft;
  }
};

function useScrollAnimation(deps) {
  const state = useRef([]);
  const dispatch = useCallback(
    (action) => {
      // if (action.type != 'updateScroll') {
      //   console.log('dispatching', { state, action });
      // }
      state.current = reducer(state.current, action);
      state.current.map((item) => {
        if (item.didUpdate && item.onUpdate) {
          item.onUpdate(item);
        }
      });
    },
    [state.current]
  );

  useEffect(() => {
    // console.log('scrollanim Effect');
    let timerId = undefined;
    let scrollPos = -1;

    const tick = () => {
      if (scrollPos !== window.scrollY) {
        scrollPos = window.scrollY;
        dispatch({ type: 'updateScroll' });
      }
    };

    timerId = new AnimationFrameLoop(12, tick);
    timerId.start();

    return () => {
      timerId.stop();
    };
  }, [dispatch, ...(deps || [])]);

  return [dispatch];
}

export default useScrollAnimation;
